import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import AcmPage from './components/AcmPage';
import Duyurular from './components/Duyurular';
import AdminPanel from './components/AdminPanel';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import Signup from './components/Signup';
import EtkinliklerPage from './components/EtkinliklerPage';
import Login from './components/Login';
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { API_URL } from './config';
import CertificateEditor from './components/CertificateEditor';

function App() {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [load, setLoad] = useState(true);

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = parseJwt(token);
      if (decodedToken) {
        setIsLoggedIn(true);
        setUserRole(decodedToken.role);
        setLoad(false);
      } else {
        setIsLoggedIn(false);
        setLoad(false);
      }
    } else {
      setLoad(false);
    }
  }, []);

  const addAnnouncement = (newAnnouncement) => {
    setAnnouncements([...announcements, newAnnouncement]);
  };

  const handleLogin = (role) => {
    setIsLoggedIn(true);
    setUserRole(role);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
  };

  return (
    !load ? <Router>
      <ScrollToTop>
        <div className="App">
          <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/sertifika-editor" element={<CertificateEditor />} />
            <Route path="/acm" element={<AcmPage />} />
            <Route path="/etkinlikler" element={<EtkinliklerPage />} />
            <Route path="/iletisim" element={<Contact />} />
            <Route path="/duyurular" element={<Duyurular />} />
            <Route path="/uye-ol" element={<Signup />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} />} />
            <Route path="/AdminPaneli" element={isLoggedIn ? <AdminPanel isLoggedIn={isLoggedIn} userRole={userRole} /> : <Navigate to="/login" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </ScrollToTop>
    </Router> : null
  );
}

export default App;
