// Header.js (Masaüstü korunarak mobil eklemeler)
import React, { useState } from 'react';
import '../styles/header.css';
import logo from '../styles/acm-logoo.png';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <a href='/'><img src={logo} alt="ACM Iğdır Logo" /></a>
      <nav>
        <ul>
          <li><a href="/">Ana Sayfa</a></li>
          <li><a href="/acm">ACM Nedir?</a></li>
          <li><a href="/etkinlikler">Etkinlikler</a></li>
          <li><a href="/iletisim">İletişim</a></li>
          <li><a href="/duyurular">Duyurular</a></li>
          <li><a href="/uye-ol" className="register-btn">Üye Ol</a></li>
        </ul>
      </nav>
      <button className="burger-menu" onClick={toggleMenu}>
        &#9776;
      </button>
      {menuOpen && (
        <div className="mobile-nav">
          <a href="/">Ana Sayfa</a>
          <a href="/acm">ACM Nedir?</a>
          <a href="/etkinlikler">Etkinlikler</a>
          <a href="/iletisim">İletişim</a>
          <a href="/duyurular">Duyurular</a>
          <a href="/uye-ol" className="register-btn">Üye Ol</a>
        </div>
      )}
    </header>
  );
}

export default Header;
