import React, { useState } from 'react';
import '../styles/AnnouncementForm.css'; // Yeni CSS'i entegre ediyoruz

function AnnouncementForm({ announcement, onSave, onCancel }) {
  const [title, setTitle] = useState(announcement?.title || '');
  const [date, setDate] = useState(announcement?.date || '');
  const [shortDetail, setShortDetail] = useState(announcement?.shortDetail || '');
  const [modalDetail, setModalDetail] = useState(announcement?.modalDetail || '');
  const [cardImage, setCardImage] = useState(null);
  const [modalImage, setModalImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('title', title);
    formData.append('date', date);
    formData.append('shortDetail', shortDetail);
    formData.append('modalDetail', modalDetail);
    if (cardImage) formData.append('cardImage', cardImage);
    if (modalImage) formData.append('modalImage', modalImage);
  
    try {
      onSave(formData); // Form verisi kaydediliyor
    } catch (error) {
      console.error('Duyuru eklenirken hata oluştu:', error);
    }
  };
  
  return (
    <div className="announcement-form">
      <h2>{announcement ? 'Duyuruyu Düzenle' : 'Yeni Duyuru Ekle'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
          <label>Duyuru Başlığı</label>
        </div>
        <div className="form-group">
          <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
          <label>Tarih</label>
        </div>
        <div className="form-group">
          <textarea value={shortDetail} onChange={(e) => setShortDetail(e.target.value)} required></textarea>
          <label>Kısa Açıklama</label>
        </div>
        <div className="form-group">
          <textarea value={modalDetail} onChange={(e) => setModalDetail(e.target.value)} required></textarea>
          <label>Detaylı Açıklama</label>
        </div>
        <div className="form-group">
          <input type="file" onChange={(e) => setCardImage(e.target.files[0])} />
          <label>Kart Resmi</label>
        </div>
        <div className="form-group">
          <input type="file" onChange={(e) => setModalImage(e.target.files[0])} />
          <label>Modal Resmi</label>
        </div>
        <div className="button-group">
          <button type="submit" className="submit-btn">{announcement ? 'Duyuruyu Güncelle' : 'Duyuru Ekle'}</button>
          <button type="button" onClick={onCancel} className="cancel-btn">İptal</button>
        </div>
      </form>
    </div>
  );
}

export default AnnouncementForm;
