import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NotFound.css';

function NotFound() {
  return (
    <div className="not-found">
      <div className="not-found-container">
        <h1>404</h1>
        <h2>Sayfa Bulunamadı</h2>
        <p>Üzgünüz, böyle bir sayfa yok veya silinmiş olabilir.</p>
        <Link to="/" className="not-found-link">
          Ana sayfaya geri dön
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
