import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import '../styles/CertificateEditor.css';

const GOOGLE_FONTS = [
    // Modern Fontlar
    'Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Raleway', 'Poppins',
    'Ubuntu', 'Source Sans Pro', 'Nunito', 'Work Sans', 'Inter',
    'DM Sans', 'Rubik', 'Outfit', 'Space Grotesk',

    // Başlık Fontları
    'Playfair Display', 'Merriweather', 'Oswald', 'Archivo Black',
    'Bebas Neue', 'Anton', 'Abril Fatface', 'Crimson Pro',
    'Spectral', 'Bitter', 'Libre Baskerville', 'Lora',

    // El Yazısı Fontları
    'Dancing Script', 'Pacifico', 'Great Vibes', 'Sacramento',
    'Satisfy', 'Kaushan Script', 'Yellowtail', 'Caveat',
    'Allura', 'Alex Brush', 'Tangerine', 'Petit Formal Script',

    // Dekoratif Fontlar
    'Cinzel', 'Cormorant Garamond', 'Philosopher', 'Marcellus',
    'Pinyon Script', 'Mrs Saint Delafield', 'Italianno', 'Sofia',
    'Engagement', 'Meddon', 'Mr De Haviland', 'Rouge Script',

    // Modern Serifler
    'Gilda Display', 'Adamina', 'Mate', 'Cardo',
    'Crimson Text', 'Gentium Book Basic', 'Sorts Mill Goudy'
];

const MAX_IMAGE_SIZE = 2000;
const PREVIEW_UPDATE_DELAY = 100;
const BATCH_SIZE = 5;
const ZOOM_STEP = 0.1;
const MIN_ZOOM = 0.5;
const MAX_ZOOM = 2;

const CertificateEditor = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const isAuth = sessionStorage.getItem('certEditorAuth') === 'true';
        if (!isAuth) {
            navigate('/');
        }
    }, [navigate]);

    // State tanımlamaları
    const [certificateImage, setCertificateImage] = useState(null);
    const [nameX, setNameX] = useState(50);
    const [nameY, setNameY] = useState(50);
    const [fontSize, setFontSize] = useState(48);
    const [fontColor, setFontColor] = useState('#000000');
    const [fontFamily, setFontFamily] = useState('Roboto');
    const [previewName, setPreviewName] = useState('Örnek İsim');
    const [namesList, setNamesList] = useState('');
    const [savedPositions, setSavedPositions] = useState(() => {
        const saved = localStorage.getItem('savedPositions');
        return saved ? JSON.parse(saved) : [];
    });
    const [isGenerating, setIsGenerating] = useState(false);
    const [previewScale, setPreviewScale] = useState(1);
    const [progress, setProgress] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [showZoomControls, setShowZoomControls] = useState(false);
    const [isPdfMode, setIsPdfMode] = useState(false);

    // Referanslar
    const canvasRef = useRef(null);
    const imageRef = useRef(null);
    const previewTimeoutRef = useRef(null);
    const tooltipTimeoutRef = useRef(null);
    const fileInputRef = useRef(null);

    // Memoized değerler
    const filteredFonts = useMemo(() => {
        return GOOGLE_FONTS.filter(font =>
            font.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm]);

    // Performans optimizasyonu için useMemo kullanımı
    const canvasSize = useMemo(() => ({
        width: canvasRef.current?.width || 1000,
        height: canvasRef.current?.height || 1000
    }), [canvasRef.current]);

    // Debounced zoom kontrolü
    const debouncedZoom = useCallback(
        debounce((newZoom) => setZoomLevel(newZoom), 16),
        []
    );

    // Optimize edilmiş zoom fonksiyonları
    const handleZoomIn = useCallback(() => {
        debouncedZoom(prev => Math.min(prev + ZOOM_STEP, MAX_ZOOM));
    }, [debouncedZoom]);

    const handleZoomOut = useCallback(() => {
        debouncedZoom(prev => Math.max(prev - ZOOM_STEP, MIN_ZOOM));
    }, [debouncedZoom]);

    const handleZoomReset = useCallback(() => {
        debouncedZoom(1);
    }, [debouncedZoom]);

    // Optimize edilmiş resim yükleme
    const handleImageUpload = useCallback((event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (!file.type.startsWith('image/')) {
            alert('Lütfen bir resim dosyası seçin.');
            return;
        }

        // Dosya boyutu kontrolü
        if (file.size > 5 * 1024 * 1024) {
            alert('Dosya boyutu 5MB\'dan küçük olmalıdır.');
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                // Canvas oluştur
                const canvas = document.createElement('canvas');
                let { width, height } = img;

                // Boyut optimizasyonu
                if (width > MAX_IMAGE_SIZE || height > MAX_IMAGE_SIZE) {
                    const ratio = Math.min(MAX_IMAGE_SIZE / width, MAX_IMAGE_SIZE / height);
                    width *= ratio;
                    height *= ratio;
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                // Optimize edilmiş resmi kaydet
                const optimizedDataUrl = canvas.toDataURL('image/jpeg', 0.9);
                setCertificateImage(optimizedDataUrl);

                // Otomatik ölçeklendirme
                const viewportWidth = window.innerWidth * 0.6;
                const viewportHeight = window.innerHeight * 0.8;
                const scale = Math.min(
                    viewportWidth / width,
                    viewportHeight / height
                );
                setPreviewScale(scale);

                // İsim pozisyonunu merkeze ayarla
                setNameX(width / 2);
                setNameY(height / 2);

                // Canvas'ı güncelle
                if (canvasRef.current) {
                    const previewCtx = canvasRef.current.getContext('2d');
                    canvasRef.current.width = width;
                    canvasRef.current.height = height;
                    previewCtx.drawImage(img, 0, 0, width, height);
                }
            };
            img.src = e.target.result;
        };
        reader.onerror = () => {
            alert('Dosya okunamadı.');
        };
        reader.readAsDataURL(file);
    }, []);

    // Optimize edilmiş canvas çizimi
    const drawCanvas = useCallback(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        const img = imageRef.current;

        if (!img || !img.complete) return;

        // Canvas boyutlarını ayarla
        canvas.width = img.width;
        canvas.height = img.height;

        // Performans için kompozit işlem
        ctx.globalCompositeOperation = 'copy';
        ctx.drawImage(img, 0, 0);
        ctx.globalCompositeOperation = 'source-over';

        // Pozisyon göstergesi
        ctx.beginPath();
        ctx.arc(nameX, nameY, 5, 0, 2 * Math.PI);
        ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
        ctx.fill();

        // Yardımcı çizgiler
        ctx.beginPath();
        ctx.strokeStyle = 'rgba(255, 0, 0, 0.2)';
        ctx.setLineDash([5, 5]);
        ctx.moveTo(nameX, 0);
        ctx.lineTo(nameX, canvas.height);
        ctx.moveTo(0, nameY);
        ctx.lineTo(canvas.width, nameY);
        ctx.stroke();

        // Text gölgesi ve metin
        ctx.save();
        ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
        ctx.shadowBlur = 2;
        ctx.shadowOffsetX = 1;
        ctx.shadowOffsetY = 1;
        ctx.font = `${fontSize}px "${fontFamily}"`;
        ctx.fillStyle = fontColor;
        ctx.textAlign = 'center';
        ctx.fillText(previewName, nameX, nameY);
        ctx.restore();
    }, [nameX, nameY, fontSize, fontColor, fontFamily, previewName]);

    // Font yükleme için link elementi oluştur
    const loadFont = useCallback((fontFamily) => {
        return new Promise((resolve) => {
            const link = document.createElement('link');
            link.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(' ', '+')}`;
            link.rel = 'stylesheet';
            link.onload = resolve;
            document.head.appendChild(link);
        });
    }, []);

    // Font değiştiğinde yeni fontu yükle
    const handleFontChange = useCallback(async (e) => {
        const newFont = e.target.value;
        setFontFamily(newFont);
        await loadFont(newFont);
        requestAnimationFrame(drawCanvas);
    }, [loadFont, drawCanvas]);

    // Component mount olduğunda varsayılan fontu yükle
    useEffect(() => {
        loadFont('Roboto');
    }, [loadFont]);

    // Pozisyonları kaydetme
    useEffect(() => {
        try {
            localStorage.setItem('savedPositions', JSON.stringify(savedPositions));
        } catch (error) {
            console.error('Pozisyonlar kaydedilemedi:', error);
        }
    }, [savedPositions]);

    // Canvas güncelleme
    useEffect(() => {
        if (certificateImage) {
            if (previewTimeoutRef.current) {
                clearTimeout(previewTimeoutRef.current);
            }

            previewTimeoutRef.current = setTimeout(() => {
                requestAnimationFrame(drawCanvas);
            }, PREVIEW_UPDATE_DELAY);

            return () => {
                if (previewTimeoutRef.current) {
                    clearTimeout(previewTimeoutRef.current);
                }
            };
        }
    }, [certificateImage, nameX, nameY, fontSize, fontColor, fontFamily, previewName, drawCanvas]);

    // Tooltip yönetimi
    useEffect(() => {
        return () => {
            if (tooltipTimeoutRef.current) {
                clearTimeout(tooltipTimeoutRef.current);
            }
        };
    }, []);

    const optimizeImage = useCallback((img) => {
        const canvas = document.createElement('canvas');
        let { width, height } = img;

        if (width > MAX_IMAGE_SIZE || height > MAX_IMAGE_SIZE) {
            const ratio = Math.min(MAX_IMAGE_SIZE / width, MAX_IMAGE_SIZE / height);
            width *= ratio;
            height *= ratio;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        return canvas.toDataURL('image/jpeg', 0.9);
    }, []);

    const savePosition = useCallback(() => {
        const newPosition = {
            x: nameX,
            y: nameY,
            fontSize,
            fontColor,
            fontFamily,
            timestamp: new Date().toLocaleString()
        };
        setSavedPositions(prev => [...prev, newPosition]);
    }, [nameX, nameY, fontSize, fontColor, fontFamily]);

    const loadPosition = useCallback((position) => {
        setNameX(position.x);
        setNameY(position.y);
        setFontSize(position.fontSize);
        setFontColor(position.fontColor);
        setFontFamily(position.fontFamily);
    }, []);

    const convertToPdf = useCallback(async (imageUrl, name) => {
        try {
            // Resmi Blob'a çevir
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Resmi yükle ve boyutlarını al
            const img = new Image();
            img.src = imageUrl;
            await new Promise((resolve) => {
                img.onload = resolve;
            });

            // PDF boyutlarını resme göre ayarla
            const pdfWidth = img.width;
            const pdfHeight = img.height;

            // PDF'i resim boyutlarında oluştur
            const pdf = new jsPDF({
                orientation: pdfWidth > pdfHeight ? 'landscape' : 'portrait',
                unit: 'px',
                format: [pdfWidth, pdfHeight]
            });

            // Resmi tam boyutta PDF'e ekle
            pdf.addImage(
                img,
                'JPEG',
                0,
                0,
                pdfWidth,
                pdfHeight,
                undefined,
                'FAST'
            );

            // PDF'i indir
            pdf.save(`sertifika_${name.trim().replace(/\s+/g, '_')}.pdf`);

        } catch (error) {
            console.error('PDF dönüşüm hatası:', error);
            alert(`PDF dönüşümü başarısız oldu: ${error.message}`);
            throw error;
        }
    }, []);

    const generateFiles = useCallback(async () => {
        if (!certificateImage || !namesList.trim()) {
            alert('Lütfen bir sertifika şablonu yükleyin ve isim listesi girin!');
            return;
        }

        setIsGenerating(true);
        setProgress(0);
        const names = namesList.split('\n').filter(name => name.trim());
        const totalNames = names.length;

        try {
            for (let i = 0; i < totalNames; i += BATCH_SIZE) {
                const batch = names.slice(i, Math.min(i + BATCH_SIZE, totalNames));
                await Promise.all(batch.map(async (name) => {
                    if (!name.trim()) return;

                    const canvas = document.createElement('canvas');
                    const img = imageRef.current;
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');

                    // Resmi çiz
                    ctx.drawImage(img, 0, 0);

                    // İsmi ekle
                    ctx.font = `${fontSize}px "${fontFamily}"`;
                    ctx.fillStyle = fontColor;
                    ctx.textAlign = 'center';
                    ctx.fillText(name.trim(), nameX, nameY);

                    if (isPdfMode) {
                        // PDF olarak kaydet
                        await convertToPdf(canvas.toDataURL('image/jpeg', 1.0), name);
                    } else {
                        // Resim olarak kaydet
                        const link = document.createElement('a');
                        link.download = `sertifika_${name.trim().replace(/\s+/g, '_')}.jpg`;
                        link.href = canvas.toDataURL('image/jpeg', 1.0);
                        link.click();
                    }
                }));

                setProgress(Math.min(((i + BATCH_SIZE) / totalNames) * 100, 100));
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            alert(`Tüm sertifikalar başarıyla ${isPdfMode ? 'PDF' : 'resim'} olarak oluşturuldu!`);
        } catch (error) {
            console.error('Sertifika oluşturma hatası:', error);
            alert('Sertifikalar oluşturulurken bir hata oluştu!');
        } finally {
            setIsGenerating(false);
            setProgress(0);
        }
    }, [certificateImage, namesList, fontFamily, fontSize, fontColor, nameX, nameY, isPdfMode, convertToPdf]);

    const handleKeyDown = useCallback((e) => {
        const step = e.shiftKey ? 10 : 1;
        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                setNameY(y => Math.max(0, y - step));
                break;
            case 'ArrowDown':
                e.preventDefault();
                setNameY(y => Math.min(canvasRef.current?.height || 1000, y + step));
                break;
            case 'ArrowLeft':
                e.preventDefault();
                setNameX(x => Math.max(0, x - step));
                break;
            case 'ArrowRight':
                e.preventDefault();
                setNameX(x => Math.min(canvasRef.current?.width || 1000, x + step));
                break;
            default:
                break;
        }
    }, []);

    return (
        <div className="certificate-editor" onKeyDown={handleKeyDown} tabIndex="0">
            <div className="editor-panel">
                <div className="panel-section">
                    <h3>📄 Sertifika Şablonu</h3>
                    <div className="upload-section">
                        <label htmlFor="fileInput" className="file-label">
                            Dosya Seç veya Sürükle Bırak
                            <input
                                id="fileInput"
                                ref={fileInputRef}
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                data-tooltip="Resim dosyası seçin"
                            />
                        </label>
                    </div>
                </div>

                <div className="panel-section">
                    <h3>🎯 Konum Ayarları</h3>
                    <div className="position-controls">
                        <div className="control-group">
                            <label>
                                X Pozisyonu
                                <span className="value-badge">{nameX}px</span>
                            </label>
                            <div className="slider-with-input">
                                <input
                                    type="range"
                                    min="0"
                                    max={canvasRef.current?.width || 1000}
                                    value={nameX}
                                    onChange={(e) => setNameX(Number(e.target.value))}
                                    data-tooltip="Ok tuşlarıyla hassas ayar yapabilirsiniz"
                                />
                                <input
                                    type="number"
                                    value={nameX}
                                    onChange={(e) => setNameX(Number(e.target.value))}
                                    min="0"
                                    max={canvasRef.current?.width || 1000}
                                />
                            </div>
                        </div>

                        <div className="control-group">
                            <label>
                                Y Pozisyonu
                                <span className="value-badge">{nameY}px</span>
                            </label>
                            <div className="slider-with-input">
                                <input
                                    type="range"
                                    min="0"
                                    max={canvasRef.current?.height || 1000}
                                    value={nameY}
                                    onChange={(e) => setNameY(Number(e.target.value))}
                                    data-tooltip="Ok tuşlarıyla hassas ayar yapabilirsiniz"
                                />
                                <input
                                    type="number"
                                    value={nameY}
                                    onChange={(e) => setNameY(Number(e.target.value))}
                                    min="0"
                                    max={canvasRef.current?.height || 1000}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel-section">
                    <h3>✍️ Yazı Ayarları</h3>
                    <div className="text-controls">
                        <div className="control-group">
                            <label>
                                Yazı Boyutu
                                <span className="value-badge">{fontSize}px</span>
                            </label>
                            <div className="slider-with-input">
                                <input
                                    type="range"
                                    min="12"
                                    max="120"
                                    value={fontSize}
                                    onChange={(e) => setFontSize(Number(e.target.value))}
                                />
                                <input
                                    type="number"
                                    value={fontSize}
                                    onChange={(e) => setFontSize(Number(e.target.value))}
                                    min="12"
                                    max="120"
                                />
                            </div>
                        </div>

                        <div className="control-group">
                            <label>Yazı Rengi</label>
                            <input
                                type="color"
                                value={fontColor}
                                onChange={(e) => setFontColor(e.target.value)}
                                className="color-picker"
                            />
                        </div>

                        <div className="control-group font-selector">
                            <label>Yazı Tipi</label>
                            <input
                                type="text"
                                className="font-search"
                                placeholder="🔍 Yazı tipi ara..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <select
                                value={fontFamily}
                                onChange={handleFontChange}
                                style={{ fontFamily }}
                            >
                                {filteredFonts.map(font => (
                                    <option key={font} value={font} style={{ fontFamily: font }}>
                                        {font}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="panel-section">
                    <h3>👥 İsim Listesi</h3>
                    <div className="name-input">
                        <input
                            type="text"
                            value={previewName}
                            onChange={(e) => setPreviewName(e.target.value)}
                            placeholder="✏️ Önizleme için isim girin"
                            className="preview-name-input"
                        />
                    </div>

                    <div className="names-list">
                        <textarea
                            value={namesList}
                            onChange={(e) => setNamesList(e.target.value)}
                            placeholder="📝 İsimleri her satıra bir tane gelecek şekilde girin"
                            data-tooltip="Her satıra bir isim gelecek şekilde girin"
                        />
                    </div>
                </div>

                <div className="panel-section">
                    <h3>💾 Kayıtlı Pozisyonlar</h3>
                    <button
                        className="save-btn"
                        onClick={savePosition}
                        data-tooltip="Mevcut pozisyon ayarlarını kaydet"
                    >
                        <span>💾</span> Pozisyonu Kaydet
                    </button>

                    <div className="saved-positions">
                        {savedPositions.map((pos, index) => (
                            <button
                                key={index}
                                className="load-position-btn"
                                onClick={() => loadPosition(pos)}
                                data-tooltip={`X: ${pos.x}, Y: ${pos.y}, Boyut: ${pos.fontSize}px`}
                            >
                                <span>📍</span> Pozisyon {index + 1}
                                <small>{pos.timestamp}</small>
                            </button>
                        ))}
                    </div>
                </div>

                <div className="panel-section">
                    <h3>🎯 Çıktı Formatı</h3>
                    <div className="format-controls">
                        <button
                            className={`format-btn ${!isPdfMode ? 'active' : ''}`}
                            onClick={() => setIsPdfMode(false)}
                        >
                            <span>🖼️</span> Resim (JPG)
                        </button>
                        <button
                            className={`format-btn ${isPdfMode ? 'active' : ''}`}
                            onClick={() => setIsPdfMode(true)}
                        >
                            <span>📄</span> PDF
                        </button>
                    </div>
                </div>

                <button
                    className="generate-btn"
                    onClick={generateFiles}
                    disabled={isGenerating || !certificateImage || !namesList.trim()}
                >
                    {isGenerating ? (
                        <>
                            <span>⏳</span> Sertifikalar Oluşturuluyor... {Math.round(progress)}%
                        </>
                    ) : (
                        <>
                            <span>{isPdfMode ? '📄' : '🖼️'}</span>
                            Sertifikaları {isPdfMode ? 'PDF' : 'Resim'} Olarak Oluştur
                        </>
                    )}
                </button>

                {isGenerating && (
                    <div className="progress-bar">
                        <div
                            className="progress-bar-fill"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                )}
            </div>

            <div className="preview-panel">
                <div
                    className="canvas-container"
                    style={{ transform: `scale(${zoomLevel})` }}
                    onMouseEnter={() => setShowZoomControls(true)}
                    onMouseLeave={() => setShowZoomControls(false)}
                >
                    {certificateImage && (
                        <>
                            <img
                                ref={imageRef}
                                src={certificateImage}
                                alt="Sertifika"
                                style={{ display: 'none' }}
                                onLoad={drawCanvas}
                            />
                            <canvas ref={canvasRef} className="certificate-preview" />
                        </>
                    )}

                    {showZoomControls && (
                        <div className="zoom-controls">
                            <button onClick={handleZoomIn} className="zoom-btn" title="Yakınlaştır">
                                <span>🔍+</span>
                            </button>
                            <button onClick={handleZoomReset} className="zoom-btn" title="Sıfırla">
                                <span>1:1</span>
                            </button>
                            <button onClick={handleZoomOut} className="zoom-btn" title="Uzaklaştır">
                                <span>🔍-</span>
                            </button>
                            <span className="zoom-level">{Math.round(zoomLevel * 100)}%</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

// Debounce yardımcı fonksiyonu
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

export default CertificateEditor; 