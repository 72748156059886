import React from 'react';
import '../styles/footer.css';

function Footer() {
  return (
    <footer>
      <div className="footer-section">
        <h3>Hızlı Bağlantılar</h3>
        <ul>
          <li><a href="/">Ana Sayfa</a></li>
          <li><a href="/acm">ACM Nedir?</a></li>
          <li><a href="/etkinlikler">Etkinlikler</a></li>
          <li><a href="/iletisim">İletişim</a></li>
          <li><a href="/duyurular">Duyurular</a></li>
          <li><a href="/uye-ol">Üye Ol</a></li>
        </ul>
      </div>
      <div className="footer-center">
        <h3>Bizi Takip Edin</h3>
        <div className="social-icons">
          <div className="social-icons">
            <a href="https://www.instagram.com/acmigdir/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/acm-igdir" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://x.com/acmigdir" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-x-twitter"></i>
            </a>
            <a href="https://www.youtube.com/@ACMIgdir" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
          </div>

        </div>
      </div>
      <div className="footer-section-right">
        <h3>İletişim</h3>
        <ul>
          <li><i className="fa fa-envelope"></i> iletisim@acmigdir.com</li>
          <li><i className="fa fa-map-marker"></i> Iğdır Üniversitesi Şehit Bülent Yurtseven Kampüsü</li>
        </ul>
      </div>
      <div className="footer-bottom">
        <p>© 2024 ACM Iğdır</p>
      </div>
    </footer>
  );
}

export default Footer;
