import React, { useState } from 'react';
import '../styles/Signup.css';
import { API_URL } from '../config';


function Signup() {
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    department: '',
    class: '',
    schoolNumber: '',
    referral: '',
    expectations: ''
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API_URL + '/api/members', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Üyelik kaydedildi:', formData);
        setSubmitted(true);
        setFormData({
          fullName: '',
          phone: '',
          email: '',
          department: '',
          class: '',
          schoolNumber: '',
          referral: '',
          expectations: ''
        });
      } else {
        console.error('Üyelik kaydedilirken bir hata oluştu.');
      }
    } catch (error) {
      console.error('İstek gönderilemedi:', error);
    }
  };

  return (
    <div className="signup-page">
      <h1>Üye Ol</h1>
      <form className="signup-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder=" "
            required
          />
          <label>Ad Soyad</label>
        </div>

        <div className="form-group">
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder=" "
            required
          />
          <label>Telefon Numarası</label>
        </div>

        <div className="form-group">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder=" "
            required
          />
          <label>Email</label>
        </div>

        <div className="form-group">
          <input
            type="text"
            name="department"
            value={formData.department}
            onChange={handleChange}
            placeholder=" "
            required
          />
          <label>Bölüm</label>
        </div>

        <div className="form-group">
          <input
            type="text"
            name="class"
            value={formData.class}
            onChange={handleChange}
            placeholder=" "
            required
          />
          <label>Sınıf</label>
        </div>

        <div className="form-group">
          <input
            type="text"
            name="schoolNumber"
            value={formData.schoolNumber}
            onChange={handleChange}
            placeholder=" "
            required
          />
          <label>Okul Numaranız</label>
        </div>

        <div className="form-group">
          <input
            type="text"
            name="referral"
            value={formData.referral}
            onChange={handleChange}
            placeholder=" "
            required
          />
          <label>Bizden Nasıl Haberdar Oldunuz?</label>
        </div>

        <div className="form-group">
          <textarea
            name="expectations"
            value={formData.expectations}
            onChange={handleChange}
            placeholder=" "
          />
          <label>Topluluktan Beklentileriniz</label>
        </div>

        <button type="submit" className="submit-btn">Gönder</button>
        {submitted && <p className="success-message">Kaydınız başarıyla oluşturuldu!</p>}
      </form>
    </div>
  );
}

export default Signup;
