import React, { useState, useEffect } from 'react';
import AnnouncementForm from './AnnouncementForm';
import AnnouncementList from './AnnouncementList';
import MembersList from './MembersList';
import UsersList from './UsersList';
import '../styles/AdminPanel.css';
import { API_URL } from '../config';

function AdminPanel({ isLoggedIn, userRole }) {
  const [view, setView] = useState('menu');
  const [announcements, setAnnouncements] = useState([]);
  const [messages, setMessages] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Duyuruları Getir
  useEffect(() => {
    fetch(API_URL + '/api/duyurular', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => response.json())
      .then(data => setAnnouncements(data))
      .catch(error => console.error('Duyurular alınırken hata oluştu:', error));
  }, [token]);

  // Yeni Duyuru Ekle
  const handleFormSubmit = (formData) => {
    fetch(API_URL + '/api/duyurular', {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    })
      .then(response => response.json())
      .then(addedAnnouncement => {
        setAnnouncements([...announcements, addedAnnouncement]);
        setView('menu');
      })
      .catch(error => console.error('Duyuru eklenirken hata oluştu:', error));
  };

  // Duyuru Sil
  const handleDelete = (id) => {
    fetch(API_URL + `/api/duyurular/${id}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setAnnouncements(announcements.filter(announcement => announcement._id !== id));
        alert('Duyuru başarıyla silindi.');
      })
      .catch(error => console.error('Duyuru silinirken hata oluştu:', error));
  };

  // Mesajları Getir
  const handleViewMessagesClick = () => {
    fetch(API_URL + '/api/messages', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => response.json())
      .then(data => setMessages(data))
      .catch(error => console.error('Mesajlar alınırken hata oluştu:', error));
    setView('messages');
  };

  // Mesaj Sil
  const handleDeleteMessage = (id) => {
    fetch(API_URL + `/api/messages/${id}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setMessages(messages.filter(message => message._id !== id));
        alert('Mesaj başarıyla silindi.');
      })
      .catch(error => console.error('Mesaj silinirken hata oluştu:', error));
  };

  // Üyeleri Getir
  const handleViewMembersClick = () => {
    fetch(API_URL + '/api/members', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => response.json())
      .then(data => setMembers(data))
      .catch(error => console.error('Üyeler alınırken hata oluştu:', error));
    setView('members');
  };

  // Üye Sil
  const handleDeleteMember = (id) => {
    fetch(API_URL + `/api/members/${id}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setMembers(members.filter(member => member._id !== id));
        alert('Üye başarıyla silindi.');
      })
      .catch(error => console.error('Üye silinirken hata oluştu:', error));
  };

  // Görünümleri Yönet
  const handleAddClick = () => {
    setSelectedAnnouncement(null);
    setView('form');
  };

  const handleViewListClick = () => {
    setView('list');
  };

  const handleBackToMenu = () => {
    setView('menu');
  };

  const handleViewUsersClick = () => {
    setView('users');
  };

  return (
    <div className="admin-panel">
      {view === 'menu' && (
        <div className="button-group">
          <button onClick={handleViewListClick} className="admin-btn">Mevcut Duyuruları Gör</button>
          <button onClick={handleAddClick} className="admin-btn">Yeni Duyuru Ekle</button>
          <button onClick={handleViewMessagesClick} className="admin-btn">İletişim Mesajlarını Gör</button>
          <button onClick={handleViewMembersClick} className="admin-btn">Kaydolan Üyeleri Görüntüle</button>
          {isLoggedIn && userRole === 'super-user' && (
            <button onClick={handleViewUsersClick} className="admin-btn">Yönetici Düzenle</button>
          )}
        </div>
      )}
      {view === 'list' && (
        <>
          <AnnouncementList
            announcements={announcements}
            onEdit={(announcement) => {
              setSelectedAnnouncement(announcement);
              setView('form');
            }}
            onDelete={handleDelete}
          />
          <button onClick={handleBackToMenu} className="back-btn">Geri Dön</button>
        </>
      )}
      {view === 'form' && (
        <>
          <AnnouncementForm
            announcement={selectedAnnouncement}
            onSave={handleFormSubmit}
            onCancel={handleBackToMenu}
          />
        </>
      )}
      {view === 'messages' && (
        <div>
          <h2>İletişim Mesajları</h2>
          <table>
            <thead>
              <tr>
                <th>Ad</th>
                <th>Soyad</th>
                <th>E-posta</th>
                <th>Telefon</th>
                <th>Mesaj</th>
                <th>İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message) => (
                <tr key={message._id}>
                  <td>{message.name}</td>
                  <td>{message.surname}</td>
                  <td>{message.email}</td>
                  <td>{message.phone}</td>
                  <td>{message.message}</td>
                  <td>
                    <button onClick={() => handleDeleteMessage(message._id)}>Sil</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={handleBackToMenu} className="back-btn">Geri Dön</button>
        </div>
      )}
      {view === 'members' && (
        <>
          <MembersList members={members} onDelete={handleDeleteMember} />
          <button onClick={handleBackToMenu} className="back-btn">Geri Dön</button>
        </>
      )}
      {view === 'users' && (
        <>
          <UsersList />
          <button onClick={handleBackToMenu} className="back-btn">Geri Dön</button>
        </>
      )}
    </div>
  );
}

export default AdminPanel;
