import React, { useEffect, useState } from 'react';
import '../styles/duyurular.css';
import { API_URL } from '../config';

function Duyurular() {
  const [announcements, setAnnouncements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeAnnouncement, setActiveAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/api/duyurular`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // Tarihe göre sırala (en yeni en üstte)
      const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setAnnouncements(sortedData);
    } catch (error) {
      console.error('Duyurular alınırken hata oluştu:', error);
      setError('Duyurular yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (announcement) => {
    setActiveAnnouncement(announcement);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActiveAnnouncement(null);
  };

  if (loading) {
    return (
      <div className="duyurular-page">
        <h1 className="duyurular-title">DUYURULAR</h1>
        <div className="loading-message">Duyurular yükleniyor...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="duyurular-page">
        <h1 className="duyurular-title">DUYURULAR</h1>
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="duyurular-page">
      <h1 className="duyurular-title">DUYURULAR</h1>

      <div className="duyurular-container">
        {announcements.length > 0 ? (
          announcements.map((announcement) => (
            <div key={announcement._id} className="duyuru-card" onClick={() => handleCardClick(announcement)}>
              <img src={announcement.cardImage} alt={announcement.title} className="duyuru-image" />
              <div className="duyuru-info">
                <h2>{announcement.title}</h2>
                <p>{announcement.shortDetail}</p>
                <p>{new Date(announcement.date).toLocaleDateString('tr-TR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}</p>
                <button>Detayları Gör</button>
              </div>
            </div>
          ))
        ) : (
          <div className="no-announcements">Görüntülenecek duyuru yok.</div>
        )}
      </div>

      {showModal && activeAnnouncement && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <img src={activeAnnouncement.modalImage} alt={activeAnnouncement.title} />
            <h2>{activeAnnouncement.title}</h2>
            <p>{activeAnnouncement.modalDetail}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Duyurular;
